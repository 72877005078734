$baseColor: #182153;

.darkpiece .cards {
    margin: auto -25px;
}

.card {
    text-align: left;
    display: flex;
    flex-direction: column;
    width: 33%;
    color: $baseColor;
    min-width: 318px;
    margin-bottom: 2rem;
    max-width: 310px;
    margin: 10px;
    padding: 0;
    border-radius: .25rem;
    border: 1px solid #fff;
    text-decoration: none;
    box-sizing: content-box;
    overflow: hidden;
    background: #fff;
    transition: all 0.5s;
    box-shadow: 3px 3px 5px #777, inset 0 0 15px #777;

    .close-btn {
        display: none;
        position: absolute;
        color: #000;
        padding: 6px;
        background: rgba(255, 255, 255, 0.3);
        width: 25px;
        border-radius: 31px;
        text-align: center;
        z-index: 7;
        margin-top: 5px;
        margin-left: calc(100% - 42px);
        cursor: pointer;
    }

    .card-block {
        flex: 1 1 auto;
        padding: 1.25rem;
    }

    .card-title {
        margin-bottom: .75rem;
    }

    .card-text {
        font-size: 85%;
    }

    .card-footer {
        width: 100%;
        padding: .75rem 1.25rem;
        background-color: $baseColor;
        border-top: 1px solid rgba(24, 33, 83, 0.65);
        border-radius: 0;

        .text-muted {
            color: #eee!important;
        }
    }

    .view-display {
        display: flex;
        width: 100%;
        min-height: 240px;
        overflow: hidden;
        background: #313031;
        transition: all 0.5s;

        .img-frame {
            zoom: 0.45;
        }

        .page-frame {
            height: 300px;
            overflow: hidden;

            .video-react {
                padding: 0 !important;
            }

            video {
                width: 100%;
            }
        }

        .frame {
            position: relative;
            align-self: center;
            margin: auto;
            transition: all 0.5s;

            img {
               width: 100%;
            }

            iframe {
                position: relative;
                border: 0;
                overflow: hidden;
            }

            .video-layer {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
            }
        }

        .view {
            width: 800px;
            height: 800px;
            background: #000;
            box-sizing: content-box;
            transition: all 0.5s;
        }
    }

    >img {
        margin-bottom: .75rem;
    }

    &.selected {
        position: fixed;
        max-width: none;
        left: 50%;
        top: 50%;
        min-width: 98%;
        margin: auto;
        transform: translate(-50%, -50%);
        z-index: 999999;

        .close-btn {
            display: block;
        }

        .view-display {
            width: 100%;
            height: 100%;
        }

        .frame {
            zoom: 1;
            width: 100%;
            height: 78vh;
            text-align: center;

            img {
                width: auto;
                height: 78vh;
            }
        }

        .view {
            width: 100%;
        }
    }
}
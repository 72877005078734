.frame-views {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    overflow-x: hidden; 

    .page-frame {
        position: relative;
        height: 300px;
        max-width: 30em;
        padding: 0;
        border-radius: .25rem;
        margin-bottom: 20px;
        border: 1px solid #fff;
        overflow: hidden;
        box-shadow: 3px 3px 5px #777, inset 0 0 15px #777;

        &:nth-child(2n + 1) {
            margin-right: 10px;
        }

        iframe {
            position: relative;
            width: 400%;
            height: 75em;
            transform: scale(0.25);
            transform-origin: 0 0;
        }
        
        video {
            width: 100%;
            height: 100%;
        }

        .card-details {
            position: absolute;
            max-height: 0;
            width: 100%;
            color: #eee;
            z-index: 1;
            bottom: 0;
            text-align: left;
            transition: max-height 500ms;
            box-sizing: border-box;
            background: rgba(0, 0, 0, 0.8);
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.5), inset 0 0 10px rgba(0, 0, 0, 0.5);

            p {
                padding: 10px;
                line-height: 20px;
                font-size: 14px; 
            }
        }

        &:hover .card-details {
            max-height: 300px;
        }
    }

    @media only screen and (max-width: 600px) {
        .page-frame,
        .page-frame:nth-child(2n + 1) {
            margin-right: 0;
        }
    }
}


.social-icons {
    height: 70px;

    .icon {
        display: inline-block;
        padding: 10px;
        margin: 10px 5px;
        width: 30px;
    }

    img {
        width: 100%;
    }
}
.dp-loading {
    $left: 8px 8px 32px 56px;
    $animation: 1 2 2 3;

    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: #333;

    h5 {
        color: #fff;
        margin: -20px auto;
    }

    .dp-ellipsis {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
        align-self: center;

        div {
            position: absolute;
            top: 33px;
            width: 13px;
            height: 13px;
            border-radius: 50%;
            background: #fff;
            animation-timing-function: cubic-bezier(0, 1, 1, 0);
    
            @for $i from 1 through length($left) {
                &:nth-child(#{$i}) {
                  left: nth($left, $i);
                  animation: lds-ellipsis#{nth($animation, $i)} 0.6s infinite;
                }
            }
          }
      }
}
 
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
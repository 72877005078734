$baseColor: #182153;

.darkpiece-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
  background: #fff;
  // box-shadow: 0 5px 10px rgba(0, 0, 0, 0.8), inset 0 0 10px rgba(0, 0, 0, 0.8);

  a {
    color: $baseColor;
    text-decoration: none;
  }

  nav {
    display: flex;
    justify-content: space-between;
  }

  .title-area li a {
    padding: 5px 20px;
    font-weight: normal;
    letter-spacing: 2px;
    text-shadow: 10px 5px 7px #555;

    .darkpiece-logo {
      height: 30px;
      margin-right: 15px;
      position: relative;
      top: 3px;
      filter: drop-shadow(7px 5px 5px #333);
    }
  }

  .tab-container {
    width: 45%;

    .close-btn {
      display: none;
    }
  }

  .tab-container li a {
    font-family: 'Comfortaa', cursive;
    font-size: 0.9em;
    text-transform: uppercase;

    a:hover {
      color: #fff;
      text-shadow: 0 0 5px $baseColor
    }

    &:hover,
    &.selected {
      color: #fff;
      border-radius: 50% 5px;
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.7),
                  inset 0 0 10px rgba(255,255,255, 0.8);
    }

    &:hover {
      background: rgba(24, 33, 83, 0.34);
    }

    &.selected {
      background: #182153;
      font-weight: 800;
    }
  }

  ul {
    display: flex;
    width: 100%;
    justify-content: space-around;
    padding: 20px 0;
    list-style-type: none;

    li {
      display: flex;
    }

    li a {
      padding: 20px;
    }
  }

  @media only screen and (max-width: 600px) {
    nav {
      flex-direction: column;

      ul {
        padding: 12px 0;
        justify-content: space-between;
      }

      .menu {
        width: 50px;
        height: 50px;
        margin-right: 10px;
        padding: 10px 5px 5px;
        box-sizing: border-box;
      }

      .navbar-handle {
        $height: 45px;
        display: block;
        cursor: pointer;
        position: relative;
        font-size: $height/2;
        padding: 8px 5px 0;
        height: 0;
        width: 1em * 75px / $height;
        border-top: 4px solid $baseColor;
        transition: all 0.5s linear 0.3s;

        &:before,
        &:after {
            position: absolute;
            left: 0;
            right: 0;
            content: ' ';
            border-top: 4px solid $baseColor;
            transition: all 0.5s linear 0.3s;
        }

        &:before {
            top: 1em * 17px / $height;
        }

        &:after {
            top: 0.96em;
        }

        &.close {
          border-top: 0;
          padding: 0;
          margin: 0 0 0 10px;

          &:after {
              top: 0.395em;
              transform: rotateZ(-45deg);
          }

          &:before {
              top: 0.392em;
              transform: rotateZ(45deg);
          }
        }
      }

      .tab-container {
        width: calc(100% - 50px);
        left: -100%;
        position: fixed;
        background: #fff;
        height: 100vh;
        transition: all 0.5s linear 0.3s;

        &.show {
          left: 0;
          box-shadow: 0 0 15px #150e0e
        }

        ul {
          margin-top: 60px;
          flex-direction: column;

          li {
            display: flex;
            justify-content: space-around;
            margin: 10px 0;
          }

          a {
            width: 80%;
          }
        }

        .close-btn {
          display: block;
          position: absolute;
          padding: 13px 10px;
          background: rgba(0, 0, 0, 0.1);
          width: 25px;
          border-radius: 31px;
          z-index: 7;
          top: 10px;
          right: 10px;
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        }
      }
    }
  }
}
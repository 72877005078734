$baseColor: #182153;
$skillPoint: (390px/6);

.darkpiece-about {
    background: #f1f1f1;

    @media only screen and (max-width: 600px) {
        $skillPoint: (240px/6);
    }

    .profile-timeline {
        display: flex;
    }

    .profile-image,
    .profile-info {
        width: 50%;

        img {
            width: 100%;
            margin-bottom: -3px;
            filter: grayscale(1);
        }
    }

    .profile-info {
        text-align: left;
        padding: 10px 30px;
        box-sizing: border-box;
        background: #fff;

        .row {
            position: relative;
            margin-bottom: 20px;
        }

        h3 {
            font-weight: 100;
            font-size: 2.5em;
            letter-spacing: 1px;
            margin-bottom: 3px;
        }

        h4 {
            font-weight: 100;
            font-family: 'Courier Prime', monospace;
        }

        .skill {
            margin-bottom: 25px;

            &:first-of-type {
                margin-top: 10px;
            }

            &:before {
                content: "";
                height: 2px;
                width: 90%;
                position: absolute;
                margin-top: 20px;
                border-radius: 3px;
                border-left: $skillPoint solid $baseColor;
                border-right: $skillPoint*5 solid #eeeeee;
                box-sizing: border-box;
            }

            &:after {
                content: "\265F";
                position: absolute;
                right: 0;
                font-size: 25px;
                margin-top: -7px;
            }

            &.ok {
                &:before {
                    border-left: $skillPoint*2 solid $baseColor;
                    border-right: $skillPoint*4 solid #eeeeee;
                }

                &:after {
                    content: "\265E";
                }
            }

            &.average {
                &:before {
                    border-left: $skillPoint*3 solid $baseColor;
                    border-right: $skillPoint*3 solid #eeeeee;
                }

                &:after {
                    content: "\265D";
                }
            }

            &.good {
                &:before {
                    border-left: $skillPoint*4 solid $baseColor;
                    border-right: $skillPoint*2 solid #eeeeee;
                }

                &:after {
                    content: "\265C";
                }
            }

            &.excellent {
                &:before {
                    border-left: $skillPoint*5 solid $baseColor;
                    border-right: $skillPoint solid #eeeeee;
                }

                &:after {
                    content: "\265B";
                }
            }

            &.pro {
                &:before {
                    border-left: $skillPoint*6 solid $baseColor;
                }

                &:after {
                    content: "\265A";
                }
            }
        }
    }

    .social-icons {
        background-color: rgba(24, 33, 83, 1);
    }

    .about-content {
        display: flex;

        .left-content,
        .right-content {
            position: relative;
            width: 50%;
        }

        p {
            font-family: 'Courier Prime', monospace;
            line-height: 24px;
        }

        .right-content {
            padding: 0 0 40px 40px;
            text-align: left;
            top: -9px;

            h3 {
                font-weight: 100;
                text-transform: uppercase;
                letter-spacing: 2px;
                padding-bottom: 12px;
            }

            li {
                font-size: 0.95em;
                line-height: 24px;
                margin-bottom: 5px;
            }
        }

        .left-content {
            padding: 0 40px 40px 0;
            text-align: right;
            border-right: 1px solid #aaa;

            &:before {
                content: "\25CF";
                color: $baseColor;
                position: absolute;
                right: -6px;
                top: -6px;
                font-size: 16px;
            }

            h3 {
                font-size: 1em;
                margin-top: -7px;
                font-family: 'Courier Prime', monospace;
            }
        }
    }

    @media only screen and (max-width: 600px) {
        $mSkillPoint: (275px/6);

        .profile-timeline {
          flex-direction: column;
        }

        .timeline-bg {
            padding-top: 0;
        }

        .profile-image,
        .profile-info {
            width: 100%;

            h3 {
                font-size: 2em;
            }
        }
        
        .profile-intro {
            padding: 50px 15px;
        }

        .profile-info .skill {
            &:before {
                border-left: $mSkillPoint solid $baseColor;
                border-right: $mSkillPoint*5 solid #eeeeee;
            }

            &.ok {
                &:before {
                    border-left: $mSkillPoint*2 solid $baseColor;
                    border-right: $mSkillPoint*4 solid #eeeeee;
                }
            }

            &.average {
                &:before {
                    border-left: $mSkillPoint*3 solid $baseColor;
                    border-right: $mSkillPoint*3 solid #eeeeee;
                }
            }

            &.good {
                &:before {
                    border-left: $mSkillPoint*4 solid $baseColor;
                    border-right: $mSkillPoint*2 solid #eeeeee;
                }
            }

            &.excellent {
                &:before {
                    border-left: $mSkillPoint*5 solid $baseColor;
                    border-right: $mSkillPoint*1 solid #eeeeee;
                }
            }

            &.pro {
                &:before {
                    border-left: $mSkillPoint*6 solid $baseColor;
                }
            }
        }

        .about-content.m-col {
            .left-content {
                padding-right: 30px;
                h3 {
                    font-size: 0.9em;
                }
            }

            .right-content {
                padding-left: 30px;

                h3 {
                    font-size: 1em;
                }
            }
        }

        .about-content:not(.m-col) {
            flex-direction: column;

            .left-content,
            .right-content {
                position: relative;
                width: 100%;
                padding: 0 10px 40px 40px;
                text-align: left;
                box-sizing: border-box;
            }

            .left-content {
                border: 0;

                &:before {
                    left: 23px;
                }
            }
        }
    }
}
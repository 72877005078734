
.darkpiece-css {
    .card .view-display {
        background: none;
    }

    .card-footer small {
        font-size: 0.6em;
    }
    .page-intro {
        padding-bottom: 0;
    }
}
$baseColor: #182153;

.darkpiece-footer {
    .contact-me {
        background: #f1f1f1;

        .title {
            background: #f1f1f1;
            margin-bottom: 0;
            border-bottom: 1px solid #fff;
        }
    }

    .contact-container {
        display: flex;

        .contact-form,
        .contact-info {
            width: 50%;
        }

        .contact-form {
            border-right: 1px solid #fff;
        }

        .profile-info,
        .contact-form {
            padding: 40px;
        }

        .profile-info {
            background: #f1f1f1;

            h3 {
                font-size: 2em;
            }

            h4 {
                font-size: 0.8em;
            }
        }

        .contact-form {
            display: flex;
            text-align: right;
            flex-direction: row-reverse;
            box-sizing: border-box;

            form {
                width: 450px;
            }

            input,
            textarea,
            button {
                border: 0;
                resize: none;
                width: 100%;
                height: 40px;
                padding: 10px;
                box-sizing: border-box;
                margin: 10px 0;
            }

            button {
                font-size: 1em;
                color: #fff;
                background: $baseColor;
                border: 1px solid $baseColor;

                &:not(:disabled):hover {
                    border-color: #fff;
                    box-shadow: 5px 5px 15px #aaa;
                    cursor: pointer;
                }

                &:disabled {
                    opacity: 0.3;
                }
            }

            fieldset {
                border: 0;

                &:first-of-type input {
                    width: calc(50% - 10px);

                    &:first-of-type {
                        margin-right: 20px;
                    }
                }

                textarea {
                    height: 80px;
                }
            }
        }

        .social-icons {
            border-top: 1px solid #aaa;

            .icons-wrapper  {
                margin-left: -12px;
            }

            .icon {
                width: 25px;
                margin: 10px 0;
                filter: invert(1);
            }
        }
    }

    .footer-bottom {
        padding: 15px;
        font-size: 0.6em;
    }

    @media only screen and (max-width: 600px) {
        .contact-container {
            flex-direction: column;

            .contact-form {
                width: 100%;
                padding: 20px;
            }
        }
    }
}
@import "./font-faces";

$baseColor: #182153;

.darkpiece {
  margin-top: 98px; 
  text-align: center;
  color: $baseColor;
  font-family: 'Assistant', sans-serif;

  h1,
  h4,
  .title-head {
    font-family: 'Comfortaa', cursive;
  }

  p {
    font-family: 'Assistant', sans-serif;
    line-height: 20px;
  }
}

.main-page {
  padding: 50px 15px;
  background: #f1f1f1;
}

.darkpiece-home {
  padding-top: 38px;

  .full-width {
    margin: 20px auto;
  }
}

.page {
  max-width: 970px;
  padding: 0 15px;
  margin: auto;
}

.page-intro {
  padding: 50px 25%;
  line-height: 32px;
  letter-spacing: 0.5px;

  p {
    line-height: 30px;
  }
}

.banner {
  img {
    width: 100%;
    filter: grayscale(1);
  }
}

.full-width {
  padding: 40px 0;
  margin: 50px auto;
  width: 100vw;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
}

.app-frames,
.frame-views {
  margin: 0 -15px;
}

.half-width {
  width: 50%;
}

.title-head {
  font-size: 1.5em;
  letter-spacing: 1px;
}

.darkpiece-page {
  min-height: 80vh; 
}

.darkpiece-footer {
  height: 50px;
}

.css-galary .card-footer small {
    font-size: 0.66em;
}

.screen-reader {
  display: block;
  height: 0;
  width: 0;
  overflow: hidden;
}

.more-link {
  color: $baseColor;
  text-decoration: none;
}

.profile-info {
  text-align: left;
  padding: 10px 30px;
  box-sizing: border-box;
  background: #fff;

  .row {
      position: relative;
      margin-bottom: 20px;
  }

  h3 {
      font-weight: 100;
      font-size: 2.5em;
      letter-spacing: 1px;
      margin-bottom: 3px;
  }

  h4 {
      font-weight: 100;
  }
}

@media only screen and (max-width: 600px) {
  .darkpiece {
    margin-top: 80px;
    overflow-x: hidden;

    .banner {
      margin: -15px -15px 0;
    }

    .page-intro {
      padding: 50px 5%;
    }

    .cards,
    .app-frames {
      margin: 0;
    }
    
    .card {
      margin: 20px auto;
      min-width: 100%;
      max-width: 100%;
    }
  }

  .page {
    padding: 0;
  }

  .full-width {
    margin: 30px auto;
    padding: 30px 0;
  }

  .half-width {
    width: 100%;
  }

  .darkpiece-toast {
    right: 20px;
  }
}



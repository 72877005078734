.darkpiece-toast {
    position: fixed;
    padding: 15px;
    z-index: 99999;
    bottom: 20px;
    left: 20px;
    border: 1px solid #fff;
    background: #1d873f;
    border-radius: 5px;
    color: #ffffff;
    box-shadow: 5px 5px 10px #555;

    p {
        text-align: left;
    }
}
.message-container {
    display: flex;
    flex-direction: column;

    .message {
        width: 97%;
        text-align: left;
        padding: 5px;
        border: 1px solid #aaa;
        margin-bottom: 2px;
        background: #fff;

        h3 {
            padding: 3px 0;
        }

        &-head {
            display: flex;
            justify-content: space-between;
        }
    }
}